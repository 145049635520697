import React, { useEffect } from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet-async"
import { Link, navigate, graphql } from "gatsby"
import EmailSignUp from "../components/EmailSignUp"
import HymnListItem from "../components/HymnListItem"

function search(event) {
  event.preventDefault();
  const query = document.getElementById('searchInput').value.trim();
  if (query.length) {
    navigate(`/search/?q=${encodeURI(query)}`);
  }
}

export default ({ data }) => {
  function getItemModel(edge) {
    return {
      href: edge.node.fields.slug,
      title: edge.node.frontmatter.title,
      tuneEmojis: edge.node.frontmatter.tuneEmojis,
      addedOn: new Date(edge.node.frontmatter.addedOn)
    }
  }

  useEffect(() => {
    document.getElementById('searchInput').focus();
  });

  const classicHymns = data.allMarkdownRemark.edges
    .filter(edge => !edge.node.frontmatter.hidden && edge.node.frontmatter.publicDomain === true);

  const oldHymnsWithNewMusic = data.allMarkdownRemark.edges
    .filter(edge => !edge.node.frontmatter.hidden && edge.node.frontmatter.oldHymnWithNewMusic === true);

  const newHymns = data.allMarkdownRemark.edges
    .filter(edge => !edge.node.frontmatter.hidden && edge.node.frontmatter.publicDomain !== true && edge.node.frontmatter.oldHymnWithNewMusic !== true);

  const newHymnsListItems = newHymns
    .sort((a, b) => { return a.node.frontmatter.title.localeCompare(b.node.frontmatter.title); })
    .map(edge => {
      const model = getItemModel(edge);
      return <HymnListItem key={model.href} model={model} />
    });

  const oldHymnsWithNewMusicListItems = oldHymnsWithNewMusic
    .sort((a, b) => { return a.node.frontmatter.title.localeCompare(b.node.frontmatter.title); })
    .map(edge => {
      const model = getItemModel(edge);
      return <HymnListItem key={model.href} model={model} />
    });

  const classicHymnsListItems = classicHymns
    .sort((a, b) => { return a.node.frontmatter.title.localeCompare(b.node.frontmatter.title); })
    .map(edge => {
      const model = getItemModel(edge);
      return <HymnListItem key={model.href} model={model} />
    });

  return <Layout>
    <Helmet>
      <title>Hymns Online</title>
    </Helmet>

    <h1>Welcome!</h1>

    <p>This site is a free online hymn supplement for Christians who sing a cappella, shape-note music. <i><Link to='/about'>Learn more...</Link></i></p>

    <form action="#" onSubmit={search}>
      <table className="searchBar">
        <tbody>
          <tr>
            <td><input type="text" id="searchInput" /></td>
            <td><input type="submit" value="Search" /></td>
          </tr>
        </tbody>
      </table>
    </form>

    <hr />

    <div id="home-page-hymn-lists" className="container">
      <div className="item">
        <h2>New Hymns</h2>
        <ul>{newHymnsListItems}</ul>
        <h2>Old Hymns with New Music</h2>
        <ul>{oldHymnsWithNewMusicListItems}</ul>
      </div>
      <div className="item">
        <h2>Classic Hymns</h2>
        <ul>{classicHymnsListItems}</ul>
      </div>
    </div>

    <hr />

    <div id="home-page-email-signup-container">
      <EmailSignUp />
    </div>

  </Layout>
}

export const query = graphql`
query {
  allMarkdownRemark {
    edges {
      node {
        frontmatter {
          title,
          tuneEmojis,
          hidden,
          addedOn,
          publicDomain,
          oldHymnWithNewMusic
        },
        fields {
          slug
        }
      }
    }
  }
}`;
